import React, { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact-form.css";

export default function ContactUs() {
  const form = useRef();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initialize isMobile based on the current window width

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_zzjl2yv",
        "template_3y94ou8",
        form.current,
        "2gmkXRQdtj99l2QK4"
      );

      console.log(result.text);
      toast.success("Email inviata con successo!");
    } catch (error) {
      console.error(error.text);
      toast.error(
        "Si è verificato un errore nell'invio. Si prega di riprovare più tardi."
      );
    }

    e.target.reset();
  };

  const toastContainerStyle = isMobile ? { top: "100px" } : {};

  return (
    <div>
      <form ref={form} onSubmit={sendEmail} style={{ marginBottom: "30px" }}>
        <label>Nome e cognome</label>
        <input
          type="text"
          className="form-control"
          placeholder="Inserisci il nome e il cognome"
          name="name"
        />
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Inserisci il tuo indirizzo email"
          name="email"
        />
        <label>Oggetto</label>
        <input
          type="text"
          className="form-control"
          placeholder="Inserisci l'oggetto dell'email"
          name="subject"
        />
        <label>Testo</label>
        <textarea
          className="form-control"
          cols="30"
          rows="8"
          placeholder="Inserisci il testo dell'email"
          name="message"
        ></textarea>
        <input type="submit" value="Invia" />
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        style={toastContainerStyle}
      />
    </div>
  );
}
