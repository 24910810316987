import React, { useState } from "react";
import "./expandable-card.css";
import { CustomButton } from "../../ui/CustomButton/CustomButton";

function ExpandableCard() {
  // const [state, setState] = useState({
  //   isExpanded: false,
  //   css: "card-compressed",
  // });

  // function handleCardExpansion() {
  //   let isCardExpanded = state.isExpanded;
  //   let cssExpansion = "";
  //   if (!isCardExpanded === true) {
  //     cssExpansion = "card";
  //   } else {
  //     cssExpansion = "card-compressed";
  //   }

  //   console.log("card espansa? ", !isCardExpanded);
  //   setState({
  //     ...state,
  //     isExpanded: !isCardExpanded,
  //     css: cssExpansion,
  //   });
  // }

  return (
    // <div className="card-wrapper">
    <div className="card">
      <h1>Chi siamo</h1>
      <p>
        Lo Studio é stato fondato in <b>Porlezza</b> nel 1994 dall’
        <b>avv. Ernestina Lancetti</b> ed è stato il primo studio legale ad
        operare in modo stabile ed attivo nel territorio del Porlezzese e delle
        Valli del Ceresio. Collaboratore è l’<b>avv. Paolo Battaglia</b>.
      </p>
      <div>
        Tutti sono iscritti presso l’Albo degli Avvocati di Como. L’avv.
        Lancetti, grazie alle specifiche competenze ed alla ultratrentennale
        esperienza acquisita, maturata nello svolgimento dell’attività forense,
        garantisce un approccio pluridisciplinare alle questioni e vanta
        un’approfondita conoscenza delle differenti realtà locali. Presta
        assistenza sia sotto forma di pareri e consulenze, sia sotto forma di
        assistenza giudiziale e stragiudiziale in diversi campi del diritto,
        patrocinando anche presso le Magistrature Superiori (Corte di cassazione
        e Consiglio di Stato).
      </div>
    </div>
    // </div>
  );
}

export default ExpandableCard;
